<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <div class="text-center mb-1">
          <b-img
            class="text-center"
            src="@/assets/images/logo/logo.png"
            fluid
          />
        </div>
        <h1 class="brand-text text-center text-primary ml-1">
          separis
        </h1>

        <b-card-title class="mb-1">
          {{ $g.browserLocale === 'fr' ? 'Oubliè mot de passe' : 'Passwort vergessen' }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $g.browserLocale === 'fr' ? 'Entrez votre utilisateur pour la remise du mot de passe' : 'Geben Sie Ihren Benutzer ein um das Passwort zurückzusetzen.' }}
        </b-card-text>

        <!-- form -->
        <validation-observer v-slot="{ invalid }">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="resetPwd"
          >
            <!-- email -->
            <b-form-group
              :label="$g.browserLocale === 'fr' ? 'Entrer valide courriel' : 'Benutzer/E-Mail'"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Entrer valide courriel' : 'Gültige E-Mail eingeben' }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              :disabled="invalid"
              type="submit"
            >
              {{ $g.browserLocale === 'fr' ? 'Réinitialisier le mot de passe' : 'Passwort zurücksetzen' }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> {{ $g.browserLocale === 'fr' ? 'Retourner à la registration' : 'Zurück zur Anmeldung' }}
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ben from '@/service/benutzer'

export default {
  components: {
    BImg,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    async resetPwd() {
      try {
        const r = await ben.vergessenPwd(this.userEmail, this.$g.browserLocale)
        if (r.data.length === 1) {
          this.$root.showToast(this.$g.browserLocale === 'fr' ? 'Remise du mot de passe' : 'Passwort zurücksetzen', this.$g.browserLocale === 'fr' ? "Courrier électronique avec un nouveau mot de passe a été envoyé à l'adresse indiquée" : 'E-Mail mit neuem Passwort wurde an die angegebene Adresse versandt!')
          this.$router.push({ name: 'auth-login' })
        }
      } catch (error) {
        // console.log(error)
        this.errored = true
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
